
<template>
  <div class="common-module change-module-four relative">
    <el-form :model="form" class="search-form" :inline="true" size="medium">
      <el-form-item v-if="showRadio">
        <el-radio-group v-model="form.isRelative" @change="changeType">
          <el-radio-button :label="0">绝对值</el-radio-button>
          <el-radio-button :label="1">相对值</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.info" prefix-icon="el-icon-search" @change="change">
          <el-option
            v-for="item in sensorDetaiList"
            :key="item.id"
            :label="item.subName"
            :value="item.id"
          >
          </el-option
        ></el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="form.time"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="change"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-radio-group v-model="form.type" @change="changeType('原始值')" class="select-btn">
          <el-radio-button :label="0">原始值</el-radio-button>
        </el-radio-group>
        <el-dropdown v-for="(item,index) in selectList" :key="index" @command="(command)=>{handleCommand(command, index)}">
          <el-button type="primary" :class="item.name !== item.label ? 'cur-btn' : ''">
            {{item.name}}<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(con,i) in item.list" :key="i" :command="beforeHandleCommand(con.value, con.label)">{{con.name}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-form-item>
    </el-form>
    <div class="module-content">
      <chart-three ref="chartOne" v-loading="loading" style="height: 100%" :value="chartData"></chart-three>
    </div>
  </div>
</template>

<script>
import chartThree from "../charts/chart03.vue";
export default {
  components: {
    chartThree,
  },
  data() {
    return {
      chartData: {
        legend:[],
        markLine:[],
        markLineList:[]
      },
      selectList:[
        {name:'1分钟',label:'1分钟',list:[{name:'最值和均值',value:1,label:'值'},{name:'均方根',value:1,label:'均方根'}]},
        {name:'10分钟',label:'10分钟',list:[{name:'最值和均值',value:10,label:'值'},{name:'均方根',value:10,label:'均方根'}]},
        {name:'1小时',label:'1小时',list:[{name:'最值和均值',value:60,label:'值'},{name:'均方根',value:60,label:'均方根'}]}
      ],
      form: {
        name: "",
        info: "",
        type: 0,
        time: [],
        isRelative:0
      },
      options: [],
      sensorDetaiList: [],
      loading:false,
      api:null,
      showRadio:false,
      current:null
    };
  },
  created() {
    let date = new Date()
    this.form.time[0] = this.$moment(date).subtract(0.5, "hours").format('YYYY-MM-DD HH:mm:ss');
    this.form.time[1] = this.$moment(date).format('YYYY-MM-DD HH:mm:ss')
  },
  methods: {
    beforeHandleCommand(flag, command){
      return {
        'flag': flag,
        'command': command
      }
    },
    handleCommand(e,i){
      this.current = e
      this.form.type = e.flag
      this.selectList.forEach((item,n) => {
        if (n === 1){
          item.name = item.name.substr(0,4)
        } else {
          item.name = item.name.substr(0,3)
        }
        if (n === i){
          item.name = item.name + e.command
        }
      })
      if (!this.form.info){
        this.$message.warning('请选择传感器')
        return
      }
      if (!this.form.time || this.form.time.length === 0){
        this.$message.warning('请选择时间')
        return
      }
      let params = {
        sensorDetailId: this.form.info,
        startTime:this.form.time[0],
        endTime: this.form.time[1],
        span:this.form.type
      }
      if (e.command === '值'){
        this.api = "/common/featureLineData"
        this.getData(params,'/common/featureLineData')
      } else {
        this.api = "/common/getRmsData"
        this.getData(params,'/common/getRmsData')
      }
    },
    changeType(type){
      if (type === '原始值'){
        this.current = null
      }
      if (!this.form.info){
        this.$message.warning('请选择传感器')
        return
      }
      if (!this.form.time || this.form.time.length === 0){
        this.$message.warning('请选择时间')
        return
      }
      this.selectList[0].name = '1分钟'
      this.selectList[1].name = '10分钟'
      this.selectList[2].name = '1小时'
      let params = {
        sensorDetailId: this.form.info,
        startTime:this.form.time[0],
        endTime: this.form.time[1],
      }
      if (this.current === null){
        this.api = "/common/originalData"
        this.getData(params,'/common/originalData')
      } else if (this.current.command === '值'){
        this.api = "/common/featureLineData"
        this.getData(params,'/common/featureLineData')
      } else {
        this.api = "/common/getRmsData"
        this.getData(params,'/common/getRmsData')
      }
    },
    change() {
      if (!this.form.info){
        this.$message.warning('请选择传感器')
        return
      }
      if (!this.form.time || this.form.time.length === 0){
        this.$message.warning('请选择时间')
        return
      }
      let params = {
        sensorDetailId: this.form.info,
        startTime:this.form.time[0],
        endTime: this.form.time[1],
        span:this.form.type
      }
      this.getData(params,this.api)
    },
    showChart2(list,data,i){
      let isShow = list.markLineList[i].data < list.min
      if (isShow !== true){
        list.markLineList[i].data = list.min - 10
      } else {
        list.markLineList[i].data = data.data
      }
      this.$refs["chartOne"].loadEcharts(list);
    },
    getData(data,api){
      this.loading = true
      if (this.sensorDetaiList[0].calValue || this.sensorDetaiList[0].calValue === 0){
        this.showRadio = true
      }
      this.$http.get(api,{
        params:data
      }).then(res=>{
        if(res.success){
          if (res.data && res.data.series.length > 0){
            if (this.form.isRelative == 1){
              let seriesList = res.data.series
              for(let item of seriesList){
                let obj = this.sensorDetaiList.filter(sensor => {return sensor.columnName === item.columnName})
                if (obj[0].isOpposite == 1){
                  item.data = item.data.map(function(element){
                    return Number(obj[0].calValue) - element;
                  });
                } else {
                  item.data = item.data.map(function(element){
                    return element - Number(obj[0].calValue)
                  });
                }
              }
              res.data.series = seriesList
            }
            res.data.series.forEach(item => {
              if (item.data === null){
                item.data = []
              }
              if (item.name === null){
                item.name = ''
              }
              if (item.type === null){
                item.type = 'line'
              }
              if (item.tag === null){
                item.tag = ''
              }
            })
            if (res.data.markLineList === null){
              res.data.markLineList = []
              res.data.markLine = []
            } else {
              res.data.markLineList = JSON.parse(JSON.stringify(res.data.markLineList))
              res.data.markLine = JSON.parse(JSON.stringify(res.data.markLineList))
            }
            if (this.form.isRelative == 1){
              let markLineList = res.data.markLineList
              let obj1 = this.sensorDetaiList.filter(sensor => {return sensor.columnName === res.data.series[0].columnName})
              for(let item of markLineList){
                if (obj1[0].isOpposite == 1){
                  item.data = Number(obj1[0].calValue)  - item.data
                } else {
                  item.data = item.data - Number(obj1[0].calValue)
                }
              }
              if (obj1[0].isOpposite == 1){
                res.data.max = Number((Number(obj1[0].calValue)  - res.data.max).toFixed(2))
                res.data.min = Number((Number(obj1[0].calValue)  - res.data.min).toFixed(2))
              } else {
                res.data.max = Number((res.data.max - Number(obj1[0].calValue)).toFixed(2))
                res.data.min = Number((res.data.min - Number(obj1[0].calValue)).toFixed(2))
              }
              res.data.markLineList = markLineList
            }
            res.data.colorList = ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc']
            this.chartData = res.data
          }
          this.loading = false
          this.$refs["chartOne"].loadEcharts(res.data);
        } else {
          this.$message.error(res.msg)
        }
        this.loading = false
      })
    },
		featureLineData(data){
      this.sensorDetaiList = data;
      if (this.sensorDetaiList[0].calValue || this.sensorDetaiList[0].calValue === 0){
        this.showRadio = true
      }
      if (this.sensorDetaiList.length > 0){
        this.loading = true
        this.form.info = this.sensorDetaiList[0].id
        this.api = "/common/originalData"
        this.$http.get("/common/originalData",{
          params:{
            sensorDetailId: this.form.info,
            startTime: this.form.time[0],
            endTime: this.form.time[1],
            span:this.form.type
          }
        }).then(res=>{
          if(res.success){
            if (res.data && res.data.series.length > 0){
              if (this.form.isRelative == 1){
                let seriesList = res.data.series
                for(let item of seriesList){
                  let obj = this.sensorDetaiList.filter(sensor => {return sensor.columnName === item.columnName})
                  if (obj[0].isOpposite == 1){
                    item.data = item.data.map(function(element){
                      return Number(obj[0].calValue) - element;
                    });
                  } else {
                    item.data = item.data.map(function(element){
                      return element - Number(obj[0].calValue)
                    });
                  }
                }
                res.data.series = seriesList
              }
              res.data.series.forEach(item => {
                if (item.data === null){
                  item.data = []
                }
                if (item.name === null){
                  item.name = ''
                }
                if (item.type === null){
                  item.type = 'line'
                }
                if (item.tag === null){
                  item.tag = ''
                }
              })
              if (res.data.markLineList === null){
                res.data.markLineList = []
                res.data.markLine = []
              } else {
                res.data.markLineList = JSON.parse(JSON.stringify(res.data.markLineList))
                res.data.markLine = JSON.parse(JSON.stringify(res.data.markLineList))
              }
              if (this.form.isRelative == 1){
                let markLineList = res.data.markLineList
                let obj1 = this.sensorDetaiList.filter(sensor => {return sensor.columnName === res.data.series[0].columnName})
                for(let item of markLineList){
                  if (obj1[0].isOpposite == 1){
                    item.data = Number(obj1[0].calValue)  - item.data
                  } else {
                    item.data = item.data - Number(obj1[0].calValue)
                  }
                }
                if (obj1[0].isOpposite == 1){
                  res.data.max = Number((Number(obj1[0].calValue)  - res.data.max).toFixed(2))
                  res.data.min = Number((Number(obj1[0].calValue)  - res.data.min).toFixed(2))
                } else {
                  res.data.max = Number((res.data.max - Number(obj1[0].calValue)).toFixed(2))
                  res.data.min = Number((res.data.min - Number(obj1[0].calValue)).toFixed(2))
                }
                res.data.markLineList = markLineList
              }
              res.data.colorList = ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc']
              this.chartData = res.data
            }
            this.loading = false
            this.$refs["chartOne"].loadEcharts(res.data);
          } else {
            this.$message.error(res.msg)
          }
          this.loading = false
        })
      }
		},
  },
};
</script>
<style scoped>
  .single-structure-change .change-module-four{
    height: 440px;
  }
  .common-module .module-content{
    height: calc(100% - 60px);
  }
  .common-module .search-form{
    position: static;
    display: flex;
    justify-content: flex-end;
  }
  .select-btn /deep/ .el-radio-button .el-radio-button__inner{
    background-color: #409EFF;
    border-color: #409EFF;
    color: #fff;
    border-radius: 4px 0 0 4px;
  }
  /deep/ .el-dropdown .cur-btn{
    border-color: #093e87;
    background-color: #093e87;
  }
  /deep/ .el-dropdown button{
    border-radius: 0;
    border-left: 1px solid #efefef;
  }
  /deep/ .el-dropdown:last-child button{
    border-radius: 0 4px 4px 0;
  }
  .common-module .search-form /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner{
    border-color: #093e87;
    background-color: #093e87;
  }
  .module-content{
    position: relative;
  }
  .threshold{
    position: absolute;
    right: 0;
    top: 0;
    width: 116px;
  }
  .th-item{
    display: flex;
    align-items: center;
    height: 25px;
    line-height: 25px;
  }
  .th-item:hover{
    cursor: pointer;
  }
  .th-icon{
    width: 20px;
    height: 2px;
    margin-right: 10px;
  }
  /deep/ .el-date-editor--datetimerange.el-input__inner{
    width: 380px;
  }
</style>
