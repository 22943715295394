<template>
  <div ref="dom" class="charts chart-bar"></div>
</template>

<script>
  import * as echarts from "echarts";
  import { on, off } from "@/libs/tools";
  export default {
    name: "ChartBar",
    props: {
      value: Object,
      text: String,
      subtext: String,
    },
    data() {
      return {
        datas1: [],
        datas2: [],
        datas3: [],
        datas4: [],
        xAxisData: [],
        series: [],
        dom: null,
        now: new Date(),
        client: null,
        oneDay: 1000,
        yAxisName:''
      };
    },
    mounted() {
      let self = this;
      for (var i = 0; i < 100; i++) {
        this.datas1.push(this.randomData(1));
        this.datas2.push(this.randomData(4));
        this.datas3.push(this.randomData(2));
        this.datas4.push(this.randomData(3));
      }
    },
    methods: {
      resize() {
        this.dom.resize();
      },
      loadEcharts(val) {
        // console.log(val);
        let self = this;
        let markLine = []
        for(let i =0;i < val.markLineList.length;i++){
          let color = ''
          if (val.markLineList[i].name.substr(0,4) === '一级阈值'){
            color = '#61A0A8'
          } else if (val.markLineList[i].name.substr(0,4) === '二级阈值'){
            color = '#CA8622'
          } else if (val.markLineList[i].name.substr(0,4) === '三级阈值'){
            color = '#C23531'
          }
          markLine.push({
            name: val.markLineList[i].name,
            yAxis: val.markLineList[i].data,
            lineStyle:{
              type:'solid',
              color:color,
              cap:'butt'
            },
          });
        }
        this.$nextTick(() => {
          self.xAxisData = val.xdata;
          self.series = val.series;
          self.yAxisName = val.unit
          let option = {
            tooltip: {
              trigger: "axis",
            },
            legend: {
              show: true,
              // right:120,
              top:10,
              itemStyle:{

              },
              // orient:'vertical',
              data:val.legend,
              selected:{}
            },
            grid: {
              top: 50,
              left: "6%",
              right: "6%",
              bottom: "10%",
            },
            toolbox: {
              right:20,
              top:20,
              feature: {
                dataZoom:{
                  yAxisIndex: "none"
                },
              }
            },
            xAxis: [
              {
                boundaryGap: ["2%", "2%"],
                axisLine: {
                  //坐标轴轴线相关设置。数学上的x轴
                  show: false,
                  lineStyle: {
                    color: "#C3C3C3",
                  },
                },
                axisLabel: {
                  //坐标轴刻度标签的相关设置
                  color: "#5a5a5a",
                  fontSize: 14,
                  showMaxLabel: true, //强制显示最后一个数据的刻度
                },
                splitLine: {
                  show: false,
                  lineStyle: {
                    type: "dashed",
                    color: "#4CC6FF",
                  },
                },
                axisTick: {
                  show: true,
                },
                data: self.xAxisData,
              },
            ],
            yAxis: [
              {
                name: self.yAxisName,
                nameTextStyle: {
                  color: "#333",
                },
                min:'dataMin',
                max:'dataMax',
                scale:true,
                boundaryGap: ["10%", "20%"],
                splitLine: {
                  show: true,
                  lineStyle: {
                    color: "#eaeaea",
                  },
                },
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: "#C3C3C3",
                  },
                },
                axisLabel: {
                  show: true,
                  fontSize: 14,
                  color: "#5a5a5a",
                  padding: 5,
                  formatter: function (value) {
                    if (value === 0) {
                      return value;
                    }
                    return value;
                  },
                },
                axisTick: {
                  show: false,
                },
              },
            ],
            series: []
          };
          if (self.series){
            markLine.forEach(item => {
              // option.legend[1].data.push(item.name)
              option.series.push({
                type:'line',
                name:item.name,
                symbol:'none',
                itemStyle: {
                  color:item.lineStyle.color,
                },
                lineStyle:{
                  color:item.lineStyle.color,
                },
                markLine: {
                  symbol:'none',
                  data: [item]
                }
              })
              option.legend.selected[item.name] = false
            });
            for (let i = 0; i < self.series.length;i++){
              option.series.push({
                type:'line',
                name:self.series[i].name,
                data:self.series[i].data,
                symbol:'none'
              });
            }
            for (let i = 0; i < self.series.length;i++){
              // option.legend.data.push(self.series[i].name)
              if (i === 0){
                option.legend.selected[self.series[i].name] = true
              } else {
                option.legend.selected[self.series[i].name] = false
              }
            }
          }
          if (this.dom != null && this.dom != "" && this.dom != undefined) {
            this.dom.dispose();
          }
          this.dom = echarts.init(this.$refs.dom);
          this.dom.setOption(option);
          this.dom.on('legendselectchanged',(obj) => {
            let upT = [];
            let downT = [];
            let maxV = null;
            let minV = 99999999;
            let maxData = Math.max.apply(null,self.series[0].data);
            let minData = Math.min.apply(null,self.series[0].data);
            for (const nameKey in obj.selected) {
              if(nameKey.includes("阈值上限") && obj.selected[nameKey]) upT.push(nameKey);
              if(nameKey.includes("阈值下限") && obj.selected[nameKey]) downT.push(nameKey);
            }
            let option = self.dom.getOption();
            let series = option.series;
            for (let i = 0; i < upT.length; i++) {
              let index = 0;
              for (let j = 0; j < series.length; j++) {
                if (series[j].name === upT[i]) {
                  index = j;
                  break;
                }
              }
              maxV = Math.max(maxV,series[index].markLine.data[0].yAxis)
            }
            for (let i = 0; i < downT.length; i++) {
              let index = 0;
              for (let j = 0; j < series.length; j++) {
                if (series[j].name === downT[i]) {
                  index = j;
                  break;
                }
              }
              let markValue = series[index].markLine.data[0].yAxis;
              minV = Math.min(minV, markValue);
            }
            maxV = Math.max(maxV, maxData);
            minV = Math.min(minV, minData);
            let resMax = (upT.length === 0 && downT.length === 0) ? null : maxV;
            let resMin = ((upT.length === 0 && downT.length === 0) || minV === 99999999) ? null : minV;
            let scale = 0.01;
            option.yAxis[0].max = resMax === null ? null : ((resMax > 0 ? resMax*(1+scale) : resMax*(1-scale)).toFixed(2)-0);
            option.yAxis[0].min = resMin === null ? null : ((resMin > 0 ? resMin*(1-scale) : resMin*(1+scale)).toFixed(2)-0);
            self.dom.setOption(option);
          });
          on(window, "resize", this.resize);
        });
      },
      randomData(data) {
        let self = this;
        self.now = new Date(+self.now + self.oneDay);
        return {
          name: self.now.toString(),
          value: [self.now, Math.random() * data],
        };
      },
      getMqData(body){
        for (let item of body){
          this.xAxisData.push(item.datetime);
        }
        if (this.xAxisData){
          if(this.xAxisData.length >= 1000){
            let sum = this.xAxisData.length - 1000
            this.xAxisData.splice(0,sum);
          }
        } else {
          this.xAxisData = []
        }
        // for (let item of body){
        //   this.xAxisData.push(item.datetime);
        // }
        if (this.series){
          for(let i = 0; i < this.series.length; i++){
            if(this.series[i].data.length >= 1000){
              let sum = this.series[i].data.length - 1000
              this.series[i].data.splice(0,sum);
            }
            for (let item of body){
              this.series[i].data.push(item[this.series[i].columnName])
            }
          }
        } else {
          this.series = []
        }
        if (this.series.length > 0 || this.xAxisData.length > 0){
          this.dom.setOption({
            series: this.series,
            xAxis: [{
              data: this.xAxisData
            }]
          });
        }
      },
    },

    beforeDestroy() {
      off(window, "resize", this.resize);
    },
  };
</script>
