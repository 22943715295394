<template>
  <div class="single-structure-change">
    <div class="flex-top">
      <div class="flex-top-l">
        <module-two></module-two>
      </div>
      <div class="flex-top-r">
        <div class="flex-top-con">
          <div class="flex-video" v-for="(item, i) in videoList" :key="i">
            <vue-aliplayer-v2
                :ref="'videoPlayer' + i"
                :source="item.url"
                :options="options"
                @ready="handleReady(i)"
            />
            <i class="el-icon-full-screen" @click="lookVideo(i)"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-two">
      <div class="flex-top-l flex-tow-l">
        <module-one @monitorChange="monitorChange"></module-one>
      </div>
<!--      <div class="flex-top-r flex-two-r">-->
<!--        <div class="flex-icon-list" v-for="(item,i) in iconList" :key="i">-->
<!--          <img :src="require('../../../../assets/images/single/' + item.icon + '.png')" alt="">-->
<!--          <div class="flex-icon-con">-->
<!--            <span>{{item.name}}</span>-->
<!--            <strong>{{item.value}}</strong>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <div class="flex-three">
      <div class="flex-three-l">
        <div class="nav-list">
          <div :class="navActive === index ? 'nav-con' : 'nav-con nav-con-active'" v-for="(item,index) in navList" :key="index" @click="navClick(item,index)">
            <i :class="item.icon"></i>
            <span>{{item.name}}</span>
          </div>
        </div>
        <div class="flex-line" v-if="navActive === 0">
          <module-three class="flex-left" ref="currentLine"></module-three>
        </div>
        <module-four ref="featureLine" v-if="navActive === 1"></module-four>
      </div>
<!--      <div class="flex-three-r">-->
<!--        <div class="flex-three-con">-->
<!--          <div class="line-left">-->
<!--            <span>主梁顶</span>-->
<!--            <div class="line-con">-->
<!--              <div class="line-dashed"></div>-->
<!--              <div class="line-soild" :style="'-webkit-transform:rotateZ(' + rotateLeft + 'deg);'"></div>-->
<!--            </div>-->
<!--            <span>主梁底</span>-->
<!--          </div>-->
<!--          <div class="line-center"></div>-->
<!--          <div class="line-right">-->
<!--            <div class="line-con">-->
<!--              <div class="line-dashed"></div>-->
<!--              <div class="line-soild" :style="'-webkit-transform:rotateZ(' + rotateRight + 'deg);'"></div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <el-dialog title="" :visible.sync="videoDialog" width="57.3%" top="5vh">
      <div class="dialog-video">
        <vue-aliplayer-v2
            :source="url"
            :options="options"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import Stomp from 'stompjs'
  import moduleOne from "./common/components/moduleOne";
  import moduleTwo from "./common/components/moduleTwo";
  import moduleThree from "./common/components/moduleThree.vue";
  import moduleFour from "./common/components/moduleFour.vue";
  import VueAliplayerV2 from "vue-aliplayer-v2";
  export default {
    components: {
      moduleOne,
      moduleTwo,
      moduleThree,
      moduleFour,
      VueAliplayerV2
    },
    data() {
      return {
        client: null,
        topic: null,
        sensor: null,
        list:[],
        timer:null,
        pageData:[],
        pageLoading:false,
        navList:[
          {value:0,name:'时程数据',icon:'el-icon-share'},
          {value:1,name:'特征数据',icon:'el-icon-data-line'},
        ],
        navActive:0,
        sensorData:null,
        url:'',
        videoList: [
          {
            id: "1d6e3a9558d9433ca7704b9ce470dea0",
            name: "跨中1号导梁",
            url: "http://hls01open.ys7.com/openlive/1d6e3a9558d9433ca7704b9ce470dea0.m3u8",
          },
          {
            id: "a141fbf04c0f46c0a4164e8ecc73d188",
            name: "跨中2号导梁",
            url: "http://hls01open.ys7.com/openlive/a141fbf04c0f46c0a4164e8ecc73d188.m3u8",
          },
          {
            id: "6cde4591f3124aabad65b6be5a1c41e5",
            name: "跨中3号导梁",
            url: "http://hls01open.ys7.com/openlive/6cde4591f3124aabad65b6be5a1c41e5.m3u8",
          },
          {
            id: "924defa36f614a338bd5bb388feceeaa",
            name: "跨中4号导梁",
            url: "http://hls01open.ys7.com/openlive/924defa36f614a338bd5bb388feceeaa.m3u8",
          },
        ],
        options: {
          height: "100%",
          isLive: true,
          format: "m3u8",
          autoplay: true,
          skinLayout: false
        },
        iconList:[
          {icon:'icon_fengsu',name:'风速',value:'--m/s'},
          {icon:'icon_fengxiang',name:'风向',value:'--°'},
          {icon:'icon_wendu',name:'温度',value:'--℃'},
          {icon:'icon_shidu',name:'湿度',value:'--%'},
        ],
        rotateLeft:-10,
        rotateRight:-10,
        videoDialog:false,
        scoketTimer:null
      };
    },
    created() {
      let that = this
      that.timer = setInterval(function () {
        that.$refs.currentLine.getMqData(that.list);
        that.list = []
        that.getRotate()
        that.getIconList()
      },1000)
    },
    beforeDestroy(){
      this.disconnect();
      clearInterval(this.timer);
      this.timer = null;
      if (this.scoketTimer){
        clearInterval(this.scoketTimer);
        this.scoketTimer = null;
      }
    },
    methods: {
      getRotate(){
        this.rotateLeft = Math.round(Math.random() * 40 - 20)
        this.rotateRight = Math.round(Math.random() * 40 - 20)
      },
      handleReady(index){
        this.$nextTick(() => {
          this.$refs['videoPlayer' + index][0].play();
        })
      },
      lookVideo(i){
        this.videoDialog = true;
        this.url = this.videoList[i].url;
      },
      monitorChange(data) {
        this.sensorData = data
        this.disconnect();
        // this.currentTableData(data); //数据列表
        this.currentsensorData(data)
      },
      navClick(data,i){
        this.navActive = i
        this.disconnect();
        clearInterval(this.timer);
        this.timer = null;
        let that = this
        if (i === 0){
          that.timer = setInterval(function () {
            that.$refs.currentLine.getMqData(that.list);
            that.list = []
          },1000)
          // this.currentTableData(this.sensorData); //数据列表
        }
        this.currentsensorData(this.sensorData)
      },
      // currentTableData(data) {
      //   this.pageLoading = true
      //   this.$http.get("/common/currentTableData",{
      //     params:{
      //       monitorDictId: data.id
      //     }
      //   }).then(res=>{
      //     if(res.success){
      //       this.pageData = res.data;
      //     }
      //     this.pageLoading = false
      //   })
      // },
      getIconList(){
        this.$http.get('/sensorDetail/environmentData').then(res => {
          if (res.code == 200){
            this.iconList[0].value =  res.data.windS + 'm/s'
            this.iconList[1].value =  res.data.windD + '°'
            this.iconList[2].value =  res.data.temperature + '℃'
            this.iconList[3].value =  res.data.humidity + '%'
          }
        })
      },
      currentsensorData(data) {
        this.pageLoading = true
        this.$http.get("/sensorDetail/getListByMonitorDictId",{
          params:{
            monitorDictId: data.id
          }
        }).then(res=>{
          if(res.success){
            if (this.navActive === 0){
              var p1 = this.currentLineData(res.data,data.name); //时程数据
              Promise.all([p1]).then(values => {
                this.startListen();
              }, () => {});
            } else if (this.navActive === 1){
              this.featureLineData(res.data); //最值、均值数据
            }
          }
          this.pageLoading = false
        })
      },
      //时程数据
      currentLineData(data,name){
        return this.$refs.currentLine.currentLineData(data,name)
      },
      featureLineData(data){
        this.$refs.featureLine.featureLineData(data);
      },
      // statisticsData(data,name){
      //   this.$refs.statistics.changeSensorDetail(data,name);
      // },
      //开启监听mq
      startListen(){
        this.topic = this.$refs.currentLine.topic
        var timestamp = new Date().getTime();
        var clientid = timestamp + Math.floor(Math.random() * 10000);
        var headers = {
          login: this.$MQ_USER,
          passcode: this.$MQ_PWD,
          "client-id": clientid
        };
        if (!this.client || !this.client.connected) {
          this.client = Stomp.client(this.$MQURL);
        }
        this.client.connect(
            headers,
            this.onConnected,
            this.onFailed
        );
      },
      onConnected: function(frame) {
        console.log('连接成功');
        clearInterval(this.scoketTimer);
        this.scoketTimer = null;
        var topic = "/topic/"+this.topic;
        // 订阅频道
        this.client.subscribe(topic, this.responseCallback, this.onFailed);
        this.client.debug = null;
      },
      onFailed: function(frame) {
        console.log("Failed: " + frame);
        let self = this
        this.scoketTimer = setInterval(function () {
          self.startListen()
        },5000)
      },
      responseCallback: function(frame) {
        var that = this;
        let body = JSON.parse(frame.body);
        body.forEach(item => {
          that.list.push(item)
        })
        // this.$refs.currentTable.getMqData(body[body.length - 1]);
      },
      disconnect: function() {
        if(this.client){
          let that = this
          that.client.disconnect(function(e) {
            that.client = null
          });
        }
      },
    },
  };
</script>
<style scoped>
  .single-structure-change{
    height: 100%;
    overflow-y: auto;
  }
  .nav-list{
    width: 100%;
    height: 40px;
    line-height: 40px;
    display: flex;
  }
  .nav-con{
    width: 140px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    border-left: 1px solid #1A67D0;
    border-right: 1px solid #1A67D0;
    border-top: 1px solid #1A67D0;
    color: #1A67D0;
    font-weight: bold;
  }
  .nav-con:hover{
    cursor: pointer;
  }
  .nav-con i{
    color: #1A67D0;
    margin-right: 5px;
    font-weight: bold;
    font-size: 20px;
  }
  .nav-con-active{
    color: #fff;
    background: #1A67D0;
    border-bottom: 1px solid #1A67D0;
  }
  .nav-con-active i{
    color: #fff;
  }
  .flex-line{
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: #fff;
    margin-bottom: 20px;
  }
  .flex-left{
    width: 98%;
  }
  .flex-top{
    width: 100%;
    height: 548px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .flex-top-l{
    width: 75%;
    height: 100%;
    margin-right: 10px;
  }
  .flex-top-r{
    width: calc(25% - 10px);
    height: 100%;
    background: #fff;
    padding: 10px;
  }
  .flex-top-con{
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
  ::-webkit-scrollbar {
    width: 4px; /*滚动条宽度*/
    height: 4px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: 0px 1px 3px #D0D0D0 inset; /*滚动条的背景区域的内阴影*/
    border-radius: 10px; /*滚动条的背景区域的圆角*/
    background-color: #D0D0D0; /*滚动条的背景颜色*/
  }
  ::-webkit-scrollbar-thumb {
    box-shadow: 0px 1px 3px #828282 inset; /*滚动条的内阴影*/
    border-radius: 10px; /*滚动条的圆角*/
    background-color: #828282; /*滚动条的背景颜色*/
  }
  .flex-video{
    width: 100%;
    height: 210px;
    background: #000;
    margin-bottom: 10px;
    border-radius: 4px;
    position: relative;
  }
  .flex-video i{
    color: #fff;
    font-size: 26px;
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
  .flex-two{
    height: 340px;
    display: flex;
    justify-content: space-between;
    overflow-y: auto;
  }
  .flex-tow-l{
    /*width: 86%;*/
    width: 100%;
  }
  .flex-two-r{
    width: calc(14% - 10px);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-content: flex-start;
    padding: 30px 20px;
  }
  .flex-icon-list{
    width: 100%;
    height: 50px;
    display: flex;
    align-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  .flex-icon-list img{
    width: 40px;
    height: 45px;
    margin-right: 10px;
  }
  .flex-icon-con{
    display: flex;
    flex-wrap: wrap;
    align-content: center;
  }
  .flex-icon-con span{
    display: block;
    width: 100%;
    font-size: 16px;
    color: #333;
  }
  .flex-icon-con strong{
    display: block;
    width: 100%;
    font-size: 18px;
    color: #2A75F2;
  }
  .flex-three{
    width: 100%;
    height: 480px;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    align-content: flex-end;
    align-items: flex-end;
  }
  .flex-three-l{
    /*width: 75%;*/
    width: 100%;
    height: 100%;
    margin-right: 10px;
  }
  .flex-three-r{
    width: calc(25% - 10px);
    height: calc(100% - 40px);
    background: #fff;
    padding: 50px 0;
  }
  .flex-three-con{
    width: 100%;
    height: 100%;
    /*background: #333;*/
    padding: 20px;
    display: flex;
    justify-content: center;
  }
  .line-left,.line-right{
    width: 30%;
    height: 100%;
    text-align: right;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
    font-size: 16px;
  }
  .line-con{
    width: 100%;
    height: 80px;
    margin: 20px 0;
    position: relative;
  }
  .line-dashed{
    width: 100%;
    height: 100%;
    border: 1px dashed #333;
  }
  .line-soild{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;
    width: 100%;
    height: 100%;
    border: 1px solid #333;
    transform-origin:right;
    /*-webkit-transform:rotate3d(0, 1, 0.1, -45deg);*/
    /*animation:turn1 3s linear infinite;*/
    /*transform: translateY(-50%);*/
    /*transform: rotateY(-60deg);*/
  }
  /*@keyframes turn1{*/
  /*  0%{-webkit-transform:rotate3d(0, 0, 0, 0deg);}*/
  /*  100%{-webkit-transform:rotate3d(0, 1, 0.1, 90deg);}*/
  /*}*/
  .line-right .line-soild{
    transform-origin:left;
    /*-webkit-transform:rotate3d(0, 1, 0.1, 80deg);*/
  }
  .line-center{
    width: 1px;
    height: 100%;
    background: #333;
    margin: 0 30px;
  }
  .dialog-video{
    width: 100%;
    height: 900px;
  }
  /deep/ .el-dialog__header{
    padding: 0;
  }
  /deep/ .el-dialog__body{
    padding: 0;
  }
  /deep/ .el-dialog__headerbtn{
    z-index: 9;
    top: 10px;
    right: 10px;
  }
  /deep/ .el-dialog__headerbtn .el-dialog__close{
    color: #fff;
    font-size: 28px;
  }
  /deep/ .el-dialog__headerbtn .el-icon-close:before{
    content:"\e79d"
  }
</style>
