<template>
  <div ref="dom" class="charts chart-bar"></div>
</template>

<script>
import * as echarts from "echarts";
import { on, off } from "@/libs/tools";
import Stomp from "stompjs";
export default {
  name: "ChartBar",
  props: {
    value: Array,
    text: String,
    subtext: String,
  },
  data() {
    return {
      datas1: [],
      datas2: [],
      datas3: [],
      datas4: [],
			xAxisData: [],
			series: [],
      dom: null,
      now: new Date(),
			client: null,
      oneDay: 1000,
      yAxisName:'',
      list:[]
    };
  },
  mounted() {},
  methods: {
    resize() {
      this.dom.resize();
    },
    loadEcharts(val,data,name) {
      if (data && name === 'GNSS'){
        this.list = data
      }
      let self = this;
      this.$nextTick(() => {
        console.log(val);
        self.xAxisData = val.xdata;
				self.series = val.series;
				self.yAxisName = val.unit
        let option = {
          tooltip: {
            trigger: "axis"
          },
          legend: {
            show: false,
            type:'scroll',
            right:0,
            top:'1%',
            pageButtonGap:80,
            orient:'vertical',
            // selected:{}
          },
          grid: {
            top: "10%",
            left: "6%",
            right: 130,
            bottom: "10%",
            // containLabel: true
          },
          xAxis: [
            {
              boundaryGap: ["2%", "2%"],
              axisLine: {
                //坐标轴轴线相关设置。数学上的x轴
                show: false,
                lineStyle: {
                  color: "#C3C3C3",
                },
              },
              axisLabel: {
                //坐标轴刻度标签的相关设置
                color: "#5a5a5a",
                fontSize: 14,
                showMaxLabel: true, //强制显示最后一个数据的刻度
              },
              splitLine: {
                show: false,
                lineStyle: {
                  type: "dashed",
                  color: "#4CC6FF",
                },
              },
              axisTick: {
                show: true,
              },
              data: self.xAxisData,
            },
          ],
          yAxis: [
            {
              name: self.yAxisName,
              nameTextStyle: {
                color: "#000",
              },
              scale:true,
              boundaryGap: ["10%", "20%"],
              splitLine: {
                show: false,
                lineStyle: {
                  color: "#eaeaea",
                },
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#C3C3C3",
                },
              },
              axisLabel: {
                show: true,
                fontSize: 14,
                color: "#5a5a5a",
                padding: 5,
                formatter: function (value) {
                  if (value === 0) {
                    return value;
                  }
                  return value;
                },
              },
              axisTick: {
                show: false,
              },
            },
          ],
          series: []
        };
        if (self.series){
          for (let item of self.series){
            option.series.push({
              type:'line',
              connectNulls:true,
              name:item.columnName,
              data:item.data,
              symbol:'none'
            })
          }
        }
        if (this.dom != null && this.dom != "" && this.dom != undefined) {
          this.dom.dispose();
        }
        self.dom = echarts.init(self.$refs.dom);
        self.dom.showLoading({
          text: '',
          color: '#1989fa',
          spinnerRadius: 10,
          lineWidth: 2,
          maskColor: 'rgba(255, 255, 255, 1)',
          zlevel: 0,
        })
        self.dom.setOption(option);
        setTimeout(() => {
          self.dom.hideLoading();
        }, 1000);
        // self.dom.setOption(option);
        on(window, "resize", this.resize);
        this.$parent.changeLoad()
      });
    },
    randomData(data) {
      let self = this;
      self.now = new Date(+self.now + self.oneDay);
      return {
        name: self.now.toString(),
        value: [self.now, Math.random() * data],
      };
    },
		getMqData(body,name,sensorData,type){
      if (name === 'GNSS'){
        if (this.list.length > 0){
          let timeArr = body.reduce(function(arr,item){
            arr.push(item.datetime);
            return arr;
          },[]);
          timeArr = Array.from(new Set(timeArr))
          let newArr = []
          for(let item of timeArr){
            let obj = {}
            obj.datetime = item
            for(let con of body){
              if (item === con.datetime){
                for(let o in con){
                  for (let arr of this.list){
                    let arr1 = arr.fieldRelation.split('-')
                    let arr2 = arr.nameRelation.split('-')
                    // console.log(o,arr1[1]);
                    if (o === arr1[0] && con.station_name === arr2[0]){
                      let name = arr2[1] + '-' + o
                      obj[name] = con[o]
                    }
                  }
                }
              }
            }
            newArr.push(obj)
          }
          body = newArr
        }
      }
      if (!this.xAxisData){
        return
      }
      for (let item of body){
        this.xAxisData.push(item.datetime);
        this.xAxisData.unshift();
      }
      if (this.xAxisData){
        if(this.xAxisData.length >= 1000){
          let sum = this.xAxisData.length - 1000
          this.xAxisData.splice(0,sum);
        }
      } else {
        this.xAxisData = []
      }
      if (this.series){
        for(let i = 0; i < this.series.length; i++){
          let obj = sensorData.filter(sensor => {return sensor.columnName === this.series[i].columnName})
          for (let item of body){
            let val = item[this.series[i].columnName] * (this.series[i].columnName.includes("rsg") ? 0.0355 : 1)
            if (type == 1){
              if (obj[0].isOpposite == 1){
                val = obj[0].calValue - val
              } else {
                val = val - obj[0].calValue
              }
            }
            this.series[i].data.push(val);
            this.series[i].data.unshift();
          }
          if(this.series[i].data.length >= 1000){
            let sum = this.series[i].data.length - 1000
            this.series[i].data.splice(0,sum);
          }
        }
      } else {
        this.series = []
      }
      if (this.series.length > 0 || this.xAxisData.length > 0){
        this.dom.setOption({
          series: this.series,
          xAxis: [{
            data: this.xAxisData
          }]
        });
      }
		},
  },

  beforeDestroy() {
    off(window, "resize", this.resize);
  },
};
</script>
