import * as THREE from 'three';
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {MeshPhongMaterial} from "three";

export class TestEngine {

    constructor(dom = new HTMLElement(), vue) {
        this.dom = dom;
        this.renderer = new THREE.WebGLRenderer({antialias: true, alpha: true});
        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera(45, (this.dom.offsetWidth-20) / (this.dom.offsetHeight-20), 1, 1000000);
        this.pointLight = new THREE.PointLight( 0xffffff, 2.5 );
        this.pointLight.position.set(0,0,0)

        // 渲染器配置
        this.renderer.setSize(this.dom.offsetWidth-20, this.dom.offsetHeight-20, true);

        // 场景配置
        // this.scene.add(new THREE.AmbientLight(0xffffff, 1)); // 加入环境光,模型各面的光照强度无差异
        // this.scene.add(this.pointLight); // 加入点光源
        this.sun = new THREE.DirectionalLight( 0xffffff, 1 );
        // this.sun.position.set(50000,50000,50000);
        this.scene.add(this.sun); // 加入平行光
        let sun1 = new THREE.DirectionalLight( 0xffffff, 2 );
        sun1.position.set(50000,50000,50000);
        // this.scene.add(sun1); // 加入平行光
        let sun2 = new THREE.DirectionalLight( 0xffffff, 2 );
        sun2.position.set(-50000,50000,-50000);
        // this.scene.add(sun2); // 加入平行光

        // this.scene.add(new THREE.AxesHelper(50000)); // 加入坐标系

        // 相机配置
        this.camera.position.set(24, 8, 20);
        this.camera.lookAt(0, 0, 0);

        // 控制器配置
        const controls = new OrbitControls(this.camera, this.renderer.domElement);

        let self = this;let meshList = [];
        const gltfLoader = new GLTFLoader();
        gltfLoader.load('/model/connect.glb', function (gltf) {
            console.log('obj',gltf);
            const model = gltf.scene;
            model.position.set(0,0,0);
            model.traverse(function (child) {
                if (child.isMesh) {
                    child.material = new MeshPhongMaterial({side: THREE.DoubleSide});
                    meshList.push(child);
                }
            })
            console.log('meshList', meshList);
            self.scene.add(...meshList);
            vue.modelMeshList = meshList;
            vue.modelScene = self.scene;
            console.log("scene",self.scene);
        })

        let positionList = {
            // 结构应变
            "FOS-43": [-11.2,0.95,0],
            "FOS-44": [-12.6,0.95,0],
            "FOS-45": [-11.2,2.85,0],
            "FOS-46": [-12.6,2.85,0],
            "FOS-47": [-4.9,0.95,0],
            "FOS-48": [-6.3,0.95,0],
            "FOS-49": [-4.9,2.85,0],
            "FOS-50": [-6.3,2.85,0],
            "FOS-51": [4.9,0.95,0],
            "FOS-52": [6.3,0.95,0],
            "FOS-53": [4.9,2.85,0],
            "FOS-54": [6.3,2.85,0],
            "FOS-55": [11.2,0.95,0],
            "FOS-56": [12.6,0.95,0],
            "FOS-57": [11.2,2.85,0],
            "FOS-58": [12.6,2.85,0],
            // 横向竖向位移
            "LDS-01":[12.6,1.9,-6.7],
            "LDS-02":[12.6,1.9,6.7],
            "LDS-03":[11.9,0.95,0],
            "LDS-04":[6.3,1.9,-6.7],
            "LDS-05":[6.3,1.9,6.7],
            "LDS-06":[5.6,0.95,0],
            "LDS-07":[-6.3,1.9,-6.7],
            "LDS-08":[-6.3,1.9,6.7],
            "LDS-09":[-5.6,0.95,0],
            "LDS-10":[-12.6,1.9,-6.7],
            "LDS-11":[-12.6,1.9,6.7],
            "LDS-12":[-11.9,0.95,0],
            // 伸缩缝及导梁位移
            "MSS-01":[-13.45,1.9,0],
            "MSS-02":[13.45,1.9,0],
            "MSS-03":[12.6,1.9,-6.7],
            "MSS-04":[12.6,1.9,6.7],
            "MSS-05":[6.3,1.9,-6.7],
            "MSS-06":[6.3,1.9,6.7],
            "MSS-07":[-6.3,1.9,-6.7],
            "MSS-08":[-6.3,1.9,6.7],
            "MSS-09":[-12.6,1.9,-6.7],
            "MSS-10":[-12.6,1.9,6.7],
            // 导梁水平度
            "LGB-01":[11.9,2.85,7.2],
            "LGB-02":[11.9,2.85,-7.2],
            "LGB-03":[5.6,2.85,7.2],
            "LGB-04":[5.6,2.85,-7.2],
            "LGB-05":[-5.6,2.85,7.2],
            "LGB-06":[-5.6,2.85,-7.2],
            "LGB-07":[-11.9,2.85,7.2],
            "LGB-08":[-11.9,2.85,-7.2],
            // 导梁竖向振动
            "VIB-01":[11.9,2.85,0],
            "VIB-02":[5.6,2.85,0],
            "VIB-03":[-5.6,2.85,0],
            "VIB-04":[-11.9,2.85,0],
            // 导梁支座反力
            "STF-01":[11.9,2.85,5.9],
            "STF-03":[5.6,2.85,5.9],
            "STF-15":[-5.6,2.85,5.9],
            "STF-04":[-11.9,2.85,5.9],
            "STF-02":[11.9,0.95,1.4],
            "STF-11":[5.6,0.95,1.4],
            "STF-06":[-5.6,0.95,1.4],
            "STF-10":[-11.9,0.95,1.4],
            "STF-13":[11.9,0.95,-1.4],
            "STF-08":[5.6,0.95,-1.4],
            "STF-16":[-5.6,0.95,-1.4],
            "STF-09":[-11.9,0.95,-1.4],
            "STF-12":[11.9,2.85,-5.9],
            "STF-14":[5.6,2.85,-5.9],
            "STF-05":[-5.6,2.85,-5.9],
            "STF-07":[-11.9,2.85,-5.9],
        }
        let pointMesh = {};
        const geometry = new THREE.SphereGeometry( 0.5, 32, 16 );
        const material = new THREE.MeshBasicMaterial( { color: 0xffff00 } );
        const sphere = new THREE.Mesh( geometry, material );
        for (const key in positionList) {
            let mesh = sphere.clone();
            mesh.name = key;
            mesh.position.set(...positionList[key]);
            // self.scene.add(mesh);
            pointMesh[key] = mesh;
        }
        vue.modelPointMesh = pointMesh;

        // 添加至dom
        this.dom.appendChild(this.renderer.domElement);
        const animation = () => {
            // console.log(self.camera.position)
            controls.update();
            let vector = this.camera.position.clone();
            let scale = 1;
            // this.pointLight.position.set(scale * vector.x, scale * vector.y,scale * vector.z); //点光源位置
            this.sun.position.set(scale * vector.x, scale * vector.y,scale * vector.z);
            this.renderer.render(this.scene, this.camera);
            requestAnimationFrame(animation);
        };
        animation();
    }}
