<template>
  <div class="common-module change-module-two relative">
    <div class="m-t-l">
      <el-tree
          ref="tree"
          :data="treeData"
          show-checkbox
          node-key="id"
          @check="changeTree"
          accordion
          :props="defaultProps">
        <span class="span-ellipsis" slot-scope="{ node,data }" :title="data.name">{{ data.name }}</span>
      </el-tree>
    </div>
    <div class="m-t-c">
      <div id="connection-model" ref="modelconnect" style="width: 100%;height: 100%"></div>
    </div>
    <div class="m-t-r">
      <el-checkbox-group v-model="checkedItems" @change="itemChange">
        <el-checkbox v-for="(item,i) in list" :label="item.id" :key="i">{{item.name}}</el-checkbox>
      </el-checkbox-group>
    </div>
  </div>
</template>

<script>
  import {TestEngine} from "@/assets/js/connect-model";

  export default {
    props: {},
    data() {
      return {
        treeData: [],
        defaultProps: {
          children: 'children',
          label: 'name'
        },
        list: [
          {id: 0, name: '伸缩缝'},
          {id: 1, name: '肋板'},
          {id: 2, name: '横梁'},
          {id: 3, name: '顶板'},
          {id: 4, name: '底板'},
          {id: 5, name: '内腹板'},
          {id: 6, name: '外腹板'},
          {id: 7, name: '导梁'},
        ],
        checkedItems: [0,1,2,3,4,5,6,7]
      };
    },
    created() {
      this.getTree()
    },
    mounted() {
      console.log('created')
      new TestEngine(this.$refs.modelconnect, this);
    },
    methods: {
      changeTree(){
        //获取所有选中节点，包括父节点
        let arr = this.$refs.tree.getCheckedNodes()
        let list = []
        //只保留选中节点的子节点
        for (let item of arr){
          if (!item.children || item.children.length === 0){
            list.push(item)
          }
        }
        console.log(list);
        console.log(this.modelPointMesh)
        // 对模型点位进行动态处理
        for (const key in this.modelPointMesh) {
          this.modelScene.remove(this.modelPointMesh[key]);
        }
        for (let i = 0; i < list.length; i++) {
          if(this.modelPointMesh[list[i].name]) this.modelScene.add(this.modelPointMesh[list[i].name]);
        }
      },
      getTree(){
        this.$http.get('/sensor/special/structure/listTree').then(res => {
          if (res.success){
            this.treeData = res.data
          }
        })
      },
      // 选中项变化
      itemChange(val) {
        this.modelScene.remove(...this.modelMeshList);
        for (let i = 0; i < val.length; i++) {
          this.modelScene.add(this.modelMeshList[val[i]]);
        }
      }
    },
  };
</script>
<style scoped>
  .common-module .module-content {
    height: 100%;
  }
  /deep/ ::-webkit-scrollbar {
    width: 4px; /*滚动条宽度*/
  }
  /*定义滚动条轨道 内阴影+圆角*/
  /deep/ ::-webkit-scrollbar-track {
    box-shadow: 0px 1px 3px #D0D0D0 inset; /*滚动条的背景区域的内阴影*/
    border-radius: 10px; /*滚动条的背景区域的圆角*/
    background-color: #D0D0D0; /*滚动条的背景颜色*/
  }
  /*定义滑块 内阴影+圆角*/
  /deep/ ::-webkit-scrollbar-thumb {
    box-shadow: 0px 1px 3px #828282 inset; /*滚动条的内阴影*/
    border-radius: 10px; /*滚动条的圆角*/
    background-color: #828282; /*滚动条的背景颜色*/
  }
  .change-module-two {
    display: flex;
    width: 100%;
    height: 100%;
    background: none;
    padding: 0;
  }
  .m-t-l {
    width: 180px;
    height: 100%;
    background: #fff;
    padding: 20px 0;
    overflow-y:auto;
  }
  .m-t-c {
    width: calc(100% - 395px);
    height: 100%;
    background: #fff;
    margin: 0 10px;
  }
  .m-t-r {
    width: 195px;
    height: 100%;
    background: #fff;
    padding: 20px 10px;
    line-height: 30px;
    overflow-y: auto;
    font-size: 16px;
  }
  .m-t-r::-webkit-scrollbar {
    width: 4px; /*滚动条宽度*/
  }
  .m-t-r::-webkit-scrollbar-track {
    box-shadow: 0px 1px 3px #D0D0D0 inset; /*滚动条的背景区域的内阴影*/
    border-radius: 10px; /*滚动条的背景区域的圆角*/
    background-color: #D0D0D0; /*滚动条的背景颜色*/
  }
  .m-t-r::-webkit-scrollbar-thumb {
    box-shadow: 0px 1px 3px #828282 inset; /*滚动条的内阴影*/
    border-radius: 10px; /*滚动条的圆角*/
    background-color: #828282; /*滚动条的背景颜色*/
  }
  .m-t-r /deep/ .el-checkbox {
    display: flex;
    align-content: center;
    align-items: center;
    height: 30px;
    width: 100%;
  }
  .m-t-r /deep/ .el-checkbox__label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  /deep/ .span-ellipsis{
    width: 100%;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
